/**
 * Terms and Conditions Page
 * 
 * Shows legal terms and conditions.
 */

import React, { useEffect, useContext } from 'react'
import { navigate } from 'gatsby'
import Fade from 'react-reveal/Fade'
import WatsonLogoSVG from '../components/svg/logo'

import '../sass/app/_login.scss'
import AppContext from '../utils/context'

const Terms = () => {
  const context = useContext(AppContext)
  useEffect(() => {
    context.updateSiteData()
  }, [])

  return (
    <Fade>
      <div className={`terms-page`} style={{ minHeight: `100vh`, overflow: `hidden`, maxWidth: `100%` }}>
        <div
          style={{
            //paddingTop: '100px',
            margin: `auto`,
            //maxWidth: '400px',
            position: `relative`,
            zIndex: `10`,
          }}>
          <div>
            <Fade top big delay={500}>
              <div
                className={`py-4 px-4 mb-5`}
                style={{
                  backgroundColor: `#F5F2ED`,
                  boxShadow: `2px 2px 5px rgba(189, 186, 186, 0.5)`,
                }}>
                <div
                  style={{
                    maxWidth: `400px`,
                    margin: `auto`,
                  }}>
                  <a href={`/`} >
                    <WatsonLogoSVG />
                  </a>
                </div>
              </div>
            </Fade>

            <Fade bottom delay={1000}>
              <div className={`mx-4`}>
                <div className={`card full-height`}>
                  <div className={`card-header text-center`}>
                    <h1>{context.site_data.terms_headline}</h1>
                  </div>
                  <div className={`card-body`}>
                    <div className={``} dangerouslySetInnerHTML={{ __html: context.site_data.terms_text }} />
                  </div>
                </div>
              </div>
                
              <div className="text-center">
                <small className="copyright" dangerouslySetInnerHTML={{ __html: context.site_data.copyright_text }} />
              </div>
            </Fade>
          </div>
        </div>

      </div>
    </Fade>
  )
}

export default Terms
